import React from 'react';
import styled from 'styled-components';

import Layout from "../components/Layout"
import Container from '../components/Container';
import Card from '../components/Card';
import SocialMediaList from '../components/SocialMediaList';

import avatarImage from '../images/avatar.jpg';

const Wrapper = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  
  min-height: 100vh;
`;

const Avatar = styled.img`
  height: 125px;
  width: 125px;

  border-radius: 50%;
  
  box-shadow: 0 5px 7px 0px rgba(0, 0, 0, 0.16);
  
  transition: transform ease-in-out .3s;
  
  &:hover {
    transform: scale(1.05);
  }
`;

const JobName = styled.h5`
  margin: 0;
`;

const NameHeader = styled.h1`
  margin-bottom: 0;
`;

const NicknameHeader = styled.h3`
  margin-top: .5em;

  color: rgba(255, 255, 255, 0.6);
  font-size: 1em;
  font-weight: 300;
`;

const IndexPage = () => (
  <Layout>
    <Wrapper>
      <Container maxWidth="720px">
        <Card>
          <Avatar src={avatarImage} />
          <NameHeader>Kamil Twardowski</NameHeader>
          <NicknameHeader>Verlikylos</NicknameHeader>
          <JobName>Fullstack Developer</JobName>
          <SocialMediaList />
        </Card>
      </Container>
    </Wrapper>
  </Layout>
);

export default IndexPage
