import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faDiscord, faFacebook, faTwitter, faTelegram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

import Button from './Button';

const StyledSocialMediaList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;

  margin: 3em 0 0 0;
  padding: 0;

  list-style: none;
`;

const SocialMediaList = () => {
  return (
    <StyledSocialMediaList>
      <li>
        <Button color="#0a66c2" href="https://www.linkedin.com/in/kamil-twardowski-bb248b166/" target="_blank">
          <FontAwesomeIcon icon={faLinkedin} size="2x" transform="shrink-4" fixedWidth />
        </Button>
      </li>
      <li>
        <Button color="#7289da" href="https://vmcshop.pro/discord" target="_blank">
          <FontAwesomeIcon icon={faDiscord} size="2x" transform="shrink-4" fixedWidth />
        </Button>
      </li>
      <li>
        <Button color="#0984e3" href="https://www.facebook.com/verlikylos" target="_blank">
          <FontAwesomeIcon icon={faFacebook} size="2x" transform="shrink-4" fixedWidth />
        </Button>
      </li>
      <li>
        <Button color="#b2bec3" href="https://github.com/Verlikylos" target="_blank">
          <FontAwesomeIcon icon={faGithub} size="2x" transform="shrink-4" fixedWidth />
        </Button>
      </li>
      <li>
        <Button color="#1da1f2" href="https://twitter.com/kamiltwardowsky" target="_blank">
          <FontAwesomeIcon icon={faTwitter} size="2x" transform="shrink-4" fixedWidth />
        </Button>
      </li>
      <li>
        <Button color="#b2bec3" href="mailto:contact@verlikylos.dev" target="_blank">
          <FontAwesomeIcon icon={faEnvelope} size="2x" transform="shrink-4" fixedWidth />
        </Button>
      </li>
      <li>
        <Button color="#0088cc" href="https://t.me/Verlikylos" target="_blank">
          <FontAwesomeIcon icon={faTelegram} size="2x" transform="shrink-4" fixedWidth />
        </Button>
      </li>
    </StyledSocialMediaList>
  );
}

export default SocialMediaList;
