import styled from 'styled-components';

const Card = styled.div`
  padding: 2em 2.5em;

  background-color: #292e3c;
  
  text-align: center;
  
  border-radius: .25rem;
  
  box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.16);
`;

export default Card;