import styled from 'styled-components';
import rgba from '@bit/ranm8.polished.color.rgba';

const Button = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 50px;
  width: 50px;
  
  margin: 0 1em;

  color: ${({color}) => color ? color : '#ffffff'};
  border-radius: 50%;
  transition: background-color ease-in-out .1s;
  
  &:hover {
    background-color: ${({color}) => color ? rgba(color, 0.1) : rgba('#ffffff', 0.5)};;
  }
`;

export default Button;